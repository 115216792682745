
  import { defineComponent, ref, computed, reactive, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { Suggestion } from "@/models/suggestions";
  import { Segment } from "@/models/segments";
  import { suggestionProviderColumns } from "@/view_scripts/table_columns/suggestion_columns";
  import { cgiConfirmModalSchema, uiCgiSwapConfirmModalSchema } from "@/view_scripts/form_schemas/modals";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { Column } from "@/models/table"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { getRefreshData } from "@/utils/refreshData"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"

  interface Props {
    suggestionIds: string[],
    columns?: Column[],
    lastSegment: Segment,
  }

  export default defineComponent({
    components: { SchemaForm },
    props: {
      suggestionIds: {
        type: Array,
        default: () => []
      },
      columns: {
        type: Array,
      },
      lastSegment: {
        type: Object,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const totalItems = ref<number>(0)
      const sample = ref()
      const rejectModal = ref()
      const confirmModal = ref()
      const ts = new Date()
      const tableRef = ref<any>(null)
      const showLoading = ref(false)
      const suggestionIdsString = computed(() => props.suggestionIds.join())
      const rejectReasonSelectorPlaceholder = {value: null, text: 'Grund für den Abbruch wählen'}
      const rejectData = reactive<{[key: string]: any}>({
        suggestionId: '',
        rejectReasons: [rejectReasonSelectorPlaceholder],
        customReason: '',
        selectedReason: 'null',
        wrongstate: false,
      })
      const confirmData = reactive<{[key: string]: any}>({
        suggestionId: '',
        currentAgEnd: null,
        currentCgiDeparture: null,
        // currentAgEnd: props.lastSegment.attributes.currentCgiDeparture?.split('T')[0],
        // currentCgiDeparture: props.lastSegment.attributes.currentCgiDeparture?.split('T')[0],
        agStart: null,
        cgiArrival: null,
        cgiDeparture: null,
      })
      const refreshData = getRefreshData()
      const rejectSuggestionId = ref<string>()

      const getItems = async(ctx: BvTableCtxObject) => {
        if (props.suggestionIds.length === 0) return
        const bkQuery = {
          'filter[id]': suggestionIdsString.value,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
        }
        await root.$store.dispatch('suggestions/load', bkQuery)
        const visorIds = extractRelationshipIds({ data: root.$store.state.suggestions.data }, 'visor')
        return Promise.all([getVisors(visorIds), getSegments()]).then( (indices) => {
          const [visorIndex, segmentIndex] = indices
          root.$store.state.suggestions.data.forEach((sugg) => {
            const visorId = (sugg as any).relationships?.visor?.data?.id
            const segmentId = (sugg as any).relationships?.segment?.data?.id
            if (sugg.attributes) {
              sugg.attributes.visor = visorIndex[visorId]
              sugg.attributes.segment = segmentIndex[segmentId]
            }
          })
          // sample.value = root.$store.state.suggestions.response
          const filteredSugs = root.$store.state.suggestions.data.filter((sug: any) => {
            return sug.relationships?.contract?.data?.id
          })
          totalItems.value = filteredSugs.length
          return filteredSugs
        })
      }

      const getVisors = async (visorIds: string[]) => {
        const visorQuery = {
          'filter[id]': visorIds.join(),
          'include': 'agency'
        }
        await root.$store.dispatch('visors/load', visorQuery)
        const visorIndex = {}
        const visorData = root.$store.state.visors.data
        visorData.forEach((v) => {
          if (v.id) visorIndex[v.id] = v
        })
        return visorIndex
      }

      const getSegments = async () => {
        const segmentQuery = {
          'filter[suggestion_id]': suggestionIdsString.value,
          'page[number]': 1,
          'page[size]': props.suggestionIds.length,
        }
        await root.$store.dispatch('segments/load', segmentQuery)
        const cGIIds = extractRelationshipIds({ data: root.$store.state.segments.data }, 'care_giver_instance')
        const cgiQuery = {
          'filter[id]': cGIIds.join(),
          'page[number]': 1,
          'page[size]': cGIIds.length,
          'include': 'care_giver'
        }
        await root.$store.dispatch('care_giver_instances/load', cgiQuery)
        const cGIIndex = {}
        const segmentIndex = {}
        root.$store.state.care_giver_instances.data.forEach((cgi) => { if (cgi.id) cGIIndex[cgi.id] = cgi })
        const retVal = root.$store.state.segments.data.forEach((seg)=>{
          const cGIId = ((seg.relationships?.care_giver_instance  as RelationshipsWithData)?.data as ResourceIdentifierObject)?.id
          if (seg.attributes) seg.attributes.careGiverInstance = cGIIndex[cGIId]
          if (seg.id) segmentIndex[seg.id] = seg
        })
        return segmentIndex
      }

      const cgiReject = async () => {
        if (rejectData.wrongstate) return
        const data = {
          usecase: 'cgi_swap_cancel',
          list_id: rejectData.selectedReason,
          custom_reason: rejectData.customReason
        }
        if (rejectData.suggestionId){
          showLoading.value = true
          try {
            await root.$store.dispatch('suggestions/edit', { id: rejectData.suggestionId, body: data })
          } finally {
            showLoading.value = false
          }
          refreshData()
          tableRef.value.$refs.table.refresh()
        }
      }

      const cgiConfirm = async () => {
        const data = {
          usecase: 'cgi_swap_confirm',
          ...confirmData
        }
        showLoading.value = true
        try {
          await root.$store.dispatch('suggestions/edit', { id: confirmData.suggestionId, body: data })
        } finally {
          refreshData()
          showLoading.value = false
          tableRef.value.$refs.table.refresh()
        }
      }

      const confirmDataUpdated = (formValue) => {
        Object.entries(formValue).forEach(([key, val], idx) => {
          // console.log('key: ', key,'val: ', val,'idx: ', idx)
          confirmData[key] = val
        })
      }

      const actions = {
        confirm: async (data: any) => {
          rejectData.wrongstate = false
          if (['Neu', 'Vorgestellt', 'Abgelehnt', 'Abgesagt', 'Abgebrochen'].includes(data.item.attributes.stage)) {
            rejectData.wrongstate = true
            prompt(root, 'Nur Angenommene BKs können bestätigt werden.')
            return null
          }
          const currentCgiDeparture = props.lastSegment.attributes.cgiDeparture
          const agEnd = props.lastSegment.attributes.agEnd
          confirmData.currentAgEnd = agEnd ? agEnd.split('T')[0] : null
          confirmData.currentCgiDeparture = currentCgiDeparture ? currentCgiDeparture.split('T')[0] : null
          confirmData.suggestionId = data.item.id
          confirmData.agStart = data.item.attributes.segment.attributes.agStart.split('T')[0]
          confirmData.cgiArrival = data.item.attributes.segment.attributes.cgiArrival?.split('T')[0]
          confirmData.cgiDeparture = data.item.attributes.segment.attributes.cgiDeparture?.split('T')[0]
          confirmModal.value.show()
        },
        cancel: async (data: any) => {
          rejectData.wrongstate = false
          if (['Abgelehnt', 'Abgesagt', 'Abgebrochen'].includes(data.item.attributes.stage)) {
            rejectData.wrongstate = true
            // prompt(root, 'Nur Vorgestellte BKs können abgelehnt werden.')
          }
          rejectData.suggestionId = data.item.id
          const listQuery = {'filter[sys_id]': 'suggestion_cancel_reasons',include: 'sublists'}
          showLoading.value = true
          try {
            await root.$store.dispatch('lists/load', listQuery)
            rejectData.rejectReasons = root.$store.state.lists.data[0].attributes.sublists
              .map((rr)=>{
                return {value: rr.id, text: rr.attributes?.name}
              })
              .concat({value: 'new', text: "Eigenen Grund angeben..."})
            rejectData.rejectReasons.unshift(rejectReasonSelectorPlaceholder)
          } finally {
            showLoading.value = false
          }
          rejectModal.value.show()
        }
      }

      const tableColumns = props.columns || suggestionProviderColumns(actions)

      return {
        getItems,
        totalItems,
        tableColumns,
        suggestionIdsString,
        sample,
        rejectModal,
        confirmModal,
        rejectData,
        confirmData,
        confirmDataUpdated,
        cgiReject,
        cgiConfirm,
        tableRef,
        showLoading,
        cgiConfirmModalSchema,
        uiCgiSwapConfirmModalSchema
      }
    }
  })
