
  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue"
  import { providerSegmentColumns } from '@/view_scripts/table_columns/segment_columns'
  import { Contract } from "@/models/contracts";
  import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
  import { Household } from "@/models/households";
  import { Segment } from "@/models/segments";
  import { CareGiverInstance } from "@/models/care_giver_instances";
  import { Suggestion } from "@/models/suggestions";
  import { CareReceiver } from "@/models/care_receivers";
  import { Visor } from "@/models/visors";
  import { apiDataMorpher, extractRelationshipIds, modelTranslation, localIndex as indexResponse, fetchRelated } from "@/utils/apiDataHelper"
  import { fullName, resourceUrl } from "@/utils/dataExtractors"
  import { AttributesObject, ResourceObject, RelationshipsWithData, ResourceIdentifierObject} from '@/models/jsonapi'
  import { BvTableCtxObject } from "bootstrap-vue";
  import Tickets from "@/components/shared/Tickets.vue"
  import BkSwapSuggestions from "@/components/provider/BKSwapSuggestions.vue"
  import ContractKeyData from "@/components/provider/ContractKeyData.vue"
  import { watchForTicketIds } from "@/view_scripts/tickets"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { segmentSchema as segmentSchema, providerUiSegmentSchema as uiSegmentSchema } from "@/view_scripts/form_schemas/segment"
  import { providerContractSchema as contractSchema, providerUiContractSchema as uiContractSchema } from "@/view_scripts/form_schemas/contract"
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import BKFormEdit from "@/components/shared/BKFormEdit.vue"
  import CareStayTable from "@/components/provider/CareStayTable.vue"
  import { confirm, prompt } from "@/utils/interactionModals"
  import { stringSort } from "@/utils/helper"
  import { provideRefreshData } from "@/utils/refreshData"
  import { Address as AddressModel } from "@/models/addresses";
  import Address from "@/components/provider/Address.vue"

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      Tickets,
      BkSwapSuggestions,
      ContractKeyData,
      SchemaForm,
      FileMetaData,
      TicketEdit,
      BKFormEdit,
      CareStayTable,
      Address
    },
    props: { id: { type: String, required: true } },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const contract = ref<ResourceObject<string, Contract>>()
      const fmds = ref<ResourceObject<string, FMDModel>[]>()
      const segments = ref<ResourceObject<string, Segment>[]>([])
      // const household = ref<ResourceObject<string, Household>>()
      const careReceivers = ref<ResourceObject<string, CareReceiver>[]>([])
      const visor = ref<ResourceObject<string, Visor>>()
      const contractAddress = ref<ResourceObject<string, AddressModel>>()
      const seller = ref<{[key:string]: any}>()
      const careStayTableItems = ref<any[]>([])
      const ticketIds = ref<string[]>([])
      const totalSegments = ref<number>(0)
      const refreshData = () => {
        const contractQuery = {
          'filter[id]': props.id,
          'include': [
            'household.lead.seller',
            'household.posting.visors.agency',
            'household.contract_address.emails',
            'household.contract_address.phones',
            'file_meta_datas',
            'segments.care_giver_instance.care_giver',
            'care_stays.segments',
            'care_stays.care_giver_instance.care_giver',
          ].join(','),
          'fields[sellers]': 'first_name,last_name,mobile_phone',
          'fields[addresses]': 'first_name,last_name',
          'fields[care_givers]': 'first_name,last_name',
          'fields[care_stays]': 'all',
          'fields[segments]': 'all',
          'fields[file_meta_datas]': 'all',
          'fields[visors]': 'designation,external_id',
          'fields[contract_addresses]': 'all',
          'fields[emails]': 'all',
          'fields[phones]': 'all',
        }
        root.$store.dispatch('contracts/loadv2', contractQuery).then((response) => {
          const localIndex = indexResponse(response.data)
          contract.value = response.data.data[0]
          if (contract.value) {
            // const hhId = extractRelationshipIds({ data: [contract.value] }, 'household')[0]
            const fmdIds = extractRelationshipIds({ data: [contract.value] }, 'fileMetaDatas')
            const css = []
            // suggestionIds.value = extractRelationshipIds({ data: [contract.value] }, 'suggestions')
            ticketIds.value = extractRelationshipIds({ data: [contract.value] }, 'tickets')
            fmds.value = fmdIds.map((id: string) => localIndex[id])
            const unsortedSegments = response.data.included.filter((e: any) => {
              if (e.type==='segments') {
                const cGI = fetchRelated(e, 'careGiverInstance', localIndex)
                const cG = fetchRelated(cGI, 'careGiver', localIndex)
                e.meta = {cG: cG, cGI: cGI}
                return true
              } else if (e.type==='care_stays') {
                const cGI = fetchRelated(e, 'careGiverInstance', localIndex)
                const cG = fetchRelated(cGI, 'careGiver', localIndex)
                e.meta = {cGName: fullName(cG), cGI: cGI, contractId: props.id, visorId: contract.value?.relationships?.visor?.data?.id}
                css.push(e)
              } else {
                return false
              }
            })
            segments.value = stringSort(unsortedSegments, (s) => s.attributes.start, false)
            careStayTableItems.value = stringSort(css, (s) => s.attributes.arrival, false)
            totalSegments.value = segments.value.length
            seller.value = response.data.included.filter((e) => e.type === 'sellers')[0]
            const agency = contract.value.relationships?.agency as RelationshipsWithData
            const ro = agency.data as ResourceIdentifierObject
            const agencyId = ro.id
            if (agencyId) {
              visor.value = response.data.included.filter((e) => {
                return e.type === 'visors' && e.relationships.agency.data.id === agencyId
              })[0]
              contract.value.meta = {visor: visor.value}
            }
            const contractAddr = response.data.included.find((e) => { return e.type === 'contract_addresses' })
            const contractAddrEmails = contractAddr.relationships?.emails?.data.map(e => e.id)
            const contractAddrPhones = contractAddr.relationships?.phones?.data.map(p => p.id)
            contractAddress.value = contractAddr ? {
              ...contractAddr,
              included: {
                emails: response.data.included?.filter((e) => { return e.type === 'emails' }).filter(e => contractAddrEmails?.includes(e.id)),
                phones: response.data.included?.filter((e) => { return e.type === 'phones' }).filter(p => contractAddrPhones?.includes(p.id)),
              }} : null 
          }
        })
      }
      refreshData()
      provideRefreshData(refreshData)
      // const contract = computed<ResourceObject<string, Contract>>(() => {
      //   return root.$store.state.contracts.element
      // })
      // const contractResponse = computed(() => root.$store.state.contracts.elementResponse)

      // const contract = computed<ResourceObject<string, Contract>>(() => {
      //   console.log('computed contract')
      //   return root.$store.state.contracts.element
      // })

      // const loadSegments = async (segmentIds: string) => {
      //   const segmentQuery = {
      //     'filter[id]': segmentIds,
      //     'include': 'care_giver_instance.care_giver',
      //     'fields[care_giver]': 'first_name,last_name'
      //   }
      //   const response = await root.$store.dispatch('segments/load2', segmentQuery)
      //   localIndex
      //   // const cgiIds = extractRelationshipIds(segs, 'care_giver_instance').join()
      //   // const query = {'filter[id]': cgiIds, include: 'care_giver'}
      //   // const cgis = (await root.$store.dispatch('care_giver_instances/load', query)).data
      //   segs.data.forEach((seg) => {
      //     const cgiId = seg.relationships.care_giver_instance.data.id
      //     const cgi = cgis.find((c) => c.id === cgiId)
      //     seg.attributes.careGiverInstance = cgi
      //   })
      //   segments.value = stringSort(segs.data, (s) => s.attributes.start, false)
      // }

      // const careGiverInstances = computed(() => {
      //   const storeData = root.$store.state.care_giver_instances.data
      //   storeData.forEach((cgi) => {
      //     const segmentId = (cgi.relationships?.segments as RelationshipsWithData).data?.[0]?.id
      //     const segment = segments.value.find((s) => s.id === segmentId)
      //     if (segment?.attributes) segment.attributes.careGiverInstance = cgi
      //   })
      //   return storeData
      // })
      // const household = computed<ResourceObject<string, Household>>(() => {
      //   const hh = (contractResponse.value?.included?.filter(obj => obj.type === 'households')[0]) as ResourceObject<string, Household>
      //   if (hh) root.$store.dispatch('care_receivers/load', {'filter[household_id]': hh.id})
      //   return hh
      // })
      const visorId = computed<string>(() => {
        return 'visorId'
      })
      // const careReceivers = computed<ResourceObject<string, CareReceiver>[]>(() => root.$store.state.care_receivers.data ?? [])
      const editSegmentModal = ref()
      const editContractModal = ref()
      const newTicketModal = ref()
      const newSuggestionModal = ref()
      const segmentToEdit = ref<ResourceObject<string, Segment>>()
      const contractToEdit = ref<ResourceObject<string, Contract>>()
      const showLoading = ref<Boolean>(false)
      // watch(contract, async (newValue) => {
      //   const segmentIds = extractRelationshipIds({ data: [newValue] }, 'segments')
      //   totalSegments.value = segmentIds.length
      //   suggestionIds.value = extractRelationshipIds({ data: [newValue] }, 'suggestions')
      //   const query = { 'filter[id]': segmentIds.join(), 'page[size]': segmentIds.length}
      //   root.$store.dispatch('segments/load', query).then((r) => {
      //     const segs = r.data
      //     const cgiIds = segs.map((s)=>s.attributes.careGiverInstanceId).join()
      //     const query = {'filter[id]': cgiIds, include: 'care_giver'}
      //     root.$store.dispatch('care_giver_instances/load', query).then((r) => {
      //       apiDataMorpher(r.data,'careGiver')
      //       segs.forEach((s) => {
      //         const cgi = r.data.find((cgi) => cgi.id === s.attributes.careGiverInstanceId)
      //         s.attributes.careGiverInstance = cgi
      //       })
      //       segments.value = stringSort(segs, (s) => s.attributes.start, false)
      //     })
      //   })
      //   root.$store.dispatch('users/get_seller', newValue).then((r)=> {
      //     seller.value = r
      //   })

      // })

      const lastSegment = computed(() => {
        if (segments.value.length === 0) return null
        if (segments.value.length === 1) return segments.value[0]
        return segments.value.sort(sortSegmentsByStart)[0]
      })

      const sortSegmentsByStart = (a, b) => {
        return ((new Date(b.attributes.start) as any) - (new Date(a.attributes.start) as any))
      }

      const editSegment = (data: any) => {
        segmentToEdit.value = JSON.parse(JSON.stringify(data.item))
        editSegmentModal.value.show()
      }

      const columns = ref(providerSegmentColumns(editSegment))

      const saveSegment = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        if (segmentToEdit.value?.id === 'new') {
          await root.$store.dispatch('segments/create', segmentToEdit.value)
        } else if (segmentToEdit.value?.id) {
          await root.$store.dispatch('segments/edit', { id: segmentToEdit.value.id, body: segmentToEdit.value })
        }
        refreshData()
        root.$nextTick(() => editSegmentModal.value.hide())
      }

      const saveContract = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        await root.$store.dispatch('contracts/edit', { id: props.id, body: contractToEdit.value })
        refreshData()
        root.$nextTick(() => editContractModal.value.hide())
      }

      const updateFiles = async (data:any) => {
        await root.$store.dispatch('contracts/edit', { id: props.id, body: data })
        refreshData()
      }

      const ticketCreated = () => {
        refreshData()
        newTicketModal.value.hide()
      }

      // const providerContract = computed(() => {
      //   if (!contract.value.attributes) return {}
      //   const {agency, ...nw} = contract.value.attributes
      //   return nw
      // })

      // const prepareBkSwapSuggest = async () => {
      //   if (household.value?.id) {
      //     const postingQuery = {
      //       'filter[household_id]': household.value.id,
      //       'include': 'visors'
      //     }
      //     const request1 = root.$store.dispatch('postings/load', postingQuery)
      //     const crQuery = {'filter[household_id]': household.value.id}
      //     const request2 = root.$store.dispatch('care_receivers/load', crQuery)
      //     return Promise.all([request1, request2])
      //   }
      // }

      const bkSubmitted = async (bkSuggestData: any) => {
        // bkSuggestData.usecase = 'swap_suggestion'
        await root.$store.dispatch('contracts/edit', { id: props.id, body: {...bkSuggestData, usecase: 'create_swap_suggestion' }})
        newSuggestionModal.value.hide()
        refreshData()
      }

      const titleActions = computed(() => {
        //if (!contract.value?.attributes) return []
        return [
          {
            title: 'Bearbeiten',
            icon: 'pencil-alt',
            tooltip: 'Vertrag bearbeiten',
            onClick: () => {
              const keys = Object.keys(contractSchema.properties.attributes.properties)
              const attrs:Contract = keys.reduce((res, k) => {
                res[k] = contract.value?.attributes?.[k]
                return res
              }, {} as Contract)
              contractToEdit.value = {attributes: attrs, type: 'contracts'}
              editContractModal.value.show()
            }
          },
          newTicketAction(newTicketModal),
          // {
          //   title: 'cgi_suggest',
          //   icon: ['fas','user-nurse'],
          //   tooltip: 'Betreuungskraft für den nächsten Wechsel vorschlagen',
          //   onClick: () => {
          //     newSuggestionModal.value.show()
          //   }
          // },
        ]
      })

      return {
        contract,
        fmds,
        visor,
        // providerContract,
        segments,
        totalSegments,
        columns,
        titleActions,
        ticketIds,
        resourceUrl,
        modelTranslation,
        editSegmentModal,
        editContractModal,
        segmentSchema,
        uiSegmentSchema,
        contractSchema,
        uiContractSchema,
        saveSegment,
        saveContract,
        segmentToEdit,
        contractToEdit,
        updateFiles,
        refreshData,
        newTicketModal,
        newSuggestionModal,
        ticketCreated,
        lastSegment,
        visorId,
        careReceivers,
        bkSubmitted,
        seller,
        showLoading,
        careStayTableItems,
        contractAddress
      }
    }
  })
