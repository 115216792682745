
  import { defineComponent, computed, getCurrentInstance } from "vue"
  import { shortDate } from "@/utils/helper"
  import { ResourceObject } from '@/models/jsonapi'
  import { Contract } from "@/models/contracts";
  import { User } from "@/models/users";
  import { fullName } from "@/utils/dataExtractors";
  import { RelationshipsWithData, ResourceIdentifierObject} from '@/models/jsonapi'

  interface Props {
    contract: ResourceObject<string, Contract>
    seller?: ResourceObject<string, User>
  }

  export default defineComponent({
    name: 'ContractKeyData',
    props: {
      contract: {
        type: Object,
        required: true
      },
      seller: { type: Object },
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const present = (val) => val != null && val != undefined
      const designation = computed(() => {
        return props.contract.meta?.visor?.attributes?.designation
      })

      const con = computed(()=>props.contract.attributes)

      const homeLink = computed(() => {
        const visor = props.contract.meta?.visor
        if (visor && visor.attributes?.externalId) {
          if ( agencyId.value == "5b5983ba4c73c6a2dc5348b0") {
            return `https://anfrage.aterima.pl/questionnaires/${visor.attributes.externalId}`
          }
        } else {return false}
      })
      
      const agencyId = computed(() => {
        const visor = props.contract.meta?.visor
        if (visor) {
          const rels = visor.relationships?.agency as RelationshipsWithData
          return (rels.data as ResourceIdentifierObject)?.id
        } else {
          return null
        }
      })

      const isAterima = computed(() => agencyId.value == "5b5983ba4c73c6a2dc5348b0")

      const aterimaDocuSignUrl = async () => {
        const resp = await root.$store.dispatch('contracts/sign_token', props.contract.id)
        window.open(resp.link, '_blank', 'noreferrer');
      }


      return {
        shortDate,
        present,
        designation,
        con,
        fullName,
        isAterima,
        homeLink,
        aterimaDocuSignUrl
      }
    }
  })
